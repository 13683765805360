import { useState, useEffect } from "react";

import './Dashboard.css';

import Cookies from 'universal-cookie';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { Link } from "react-router-dom";

import { GetDataPage } from "interface/Data";
import { GetUserData, SetUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { cookie_dash, cookie_dash_id, cookie_dash_id_classe, cookie_dash_id_module, cookie_email, cookie_page, cookie_passw, cookiesRegister } from "dataFixed";

import Model_Profile from "components/Model/Profile";
import Model_CourseDash from "components/Model/CourseDash";
import Model_MySignature from "components/Model/MySignature";
import Model_TeacherRecordings from "components/Model/TeacherRecordings";
import Model_CourseDashDetails from "components/Model/CourseDashDetails";
import Model_CourseDashStarted from "components/Model/CourseDashStarted";
import PopUp_PaymentVoucher from "components/PopUp/AddPaymentVoucher";

export default function Page_Dashboard(props){
    
    const cookies = new Cookies(); 
    const [ pageDash, setPageDash ] = useState(GetListPag('pageDash'));

    const [ showCourse, setShowCourse ] = useState(SlideShowCourse());
    const SlideOpt = {
        infinite: true,
        indicators: true,
        arrows: true,
        duration: 6000
    }; 
    function SlideShowCourse(){
        const newData = GetDataPage('course').filter(item => item.highlight == true);
        if(newData){
            return newData;
        }
        return [];
    }

    function ShowClasses(value){
        SetListPag('pageDash', value);
    }

    function ShowPageType(){
        switch (pageDash) {
            case 'course_continue':
                return <Model_CourseDashStarted ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />;

            case 'course':
                return <Model_CourseDash ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />;

            case 'my_signature':
                return <Model_MySignature ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />;

            case 'teacher_recordings':
                return <Model_TeacherRecordings ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />;

            case 'profile':
                return <Model_Profile ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />;
        }
    }

    function CheckedLogin(){
        cookies.remove(cookie_page, '', '/', cookiesRegister);
        cookies.remove(cookie_passw, '', '/', cookiesRegister);
        cookies.remove(cookie_email, '', '/', cookiesRegister);
        cookies.remove(cookie_dash, '', '/', cookiesRegister);
        cookies.remove(cookie_dash_id, '', '/', cookiesRegister);
        cookies.remove(cookie_dash_id_module, '', '/', cookiesRegister);
        cookies.remove(cookie_dash_id_classe, '', '/', cookiesRegister);
      
        SetUserData('user', {});
        SetListPag('page', 'login');  
        SetUserData('access_type', 0); 

        props.CallbackSuccess(false);
    }

    useEffect(()=>{
        RegisterListPag('pageDash', setPageDash);

        document.title = 'Área do aluno';
    }, []);

    return(
        pageDash == 'dash_classe_detalis' ? <Model_CourseDashDetails ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } /> :
        <div className="Page_Dashboard">

            <div className="slideshow">
                <Slide { ...SlideOpt }>
                    {
                        showCourse.map((elem, index)=>{              
                            return(
                                <div className="each-slide-effect" key={ index }>
                                    <div className="container">
                                        <div className="div_inf_course">
                                            <div className="highlight_title">
                                                { elem.title }
                                            </div>
                                            <div className="highlight_text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                            <div className="button">                                                
                                                <a className="" href={ "/" + elem.title_url }>
                                                    <div className="button_text" onClick={ ()=>{ SetListPag('page', "details"); } }>
                                                        Detalhes sobre o curso
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="div_img">
                                            <img alt="img" src={ elem.img } className="img_course" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slide>
            </div>

            <div className="div_opt_menu_dash">
                <div className="container">
                    <div className="dash_title">
                        <div className="">Minha área</div>
                        <div className="list_opt">
                            {
                                GetUserData('user').courses_started.length > 0 ? 
                                <div className={ pageDash == "course_continue" ? "show_opt_active" : "show_opt" } onClick={ ()=>{ ShowClasses('course_continue') } }>
                                    Continuar assistindo
                                </div> : null
                            }
                            <div className={ pageDash == "course" ? "show_opt_active" : "show_opt" } onClick={ ()=>{ ShowClasses('course') } }>
                                Cursos
                            </div>
                            <div className={ pageDash == "my_signature" ? "show_opt_active" : "show_opt" } onClick={ ()=>{ ShowClasses('my_signature') } }>
                                Minha assinatura
                            </div>
                            {
                                GetUserData('user').recording.length > 0 ? 
                                <div className={ pageDash == "teacher_recordings" ? "show_opt_active" : "show_opt" } onClick={ ()=>{ ShowClasses('teacher_recordings') } }>
                                    Gravações com o professor
                                </div> : null
                            }
                            <div className={ pageDash == "profile" ? "show_opt_active" : "show_opt" } onClick={ ()=>{ ShowClasses('profile') } }>
                                Perfil
                            </div>
                            <Link to="/login" className="show_opt">
                                <div onClick={ ()=>{ CheckedLogin() } }>
                                    Sair
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_inf">
                { 
                    ShowPageType()
                }
            </div>
            <PopUp_PaymentVoucher setLoading={ props.setLoading } />
        </div>
    )
}