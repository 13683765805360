import Axios from 'axios';

import { cookie_dash, cookie_dash_id, cookie_dash_id_classe, cookie_dash_id_module, cookie_email, cookie_passw, cookie_site, cookiesRegister } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';

import Cookies from 'universal-cookie';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies(); 
    const data    = new FormData();

    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {        
        switch (response.data.type) {
            case "certo":
                    CallbackSuccess();

                    // Obtém a data atual
                    const day = new Date();

                    // Adiciona 3 dias
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);

                    cookies.set(cookie_passw, response.data.user.pass, { path: '/', expires: add3Days }, cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, { path: '/', expires: add3Days }, cookiesRegister);

                    if(cookies.get(cookie_dash)){
                        let cookies_page = cookies.get(cookie_dash);
                        SetListPag('pageDash', cookies_page); 

                    }else {                        
                        SetListPag('pageDash', 'course'); 
                    }
                    
                    if(cookies.get(cookie_dash_id)){
                        let cookies_page = cookies.get(cookie_dash_id); 
                        SetListPag('pageDash_id_course', cookies_page); 
                    }
                    
                    if(cookies.get(cookie_dash_id_module)){
                        let cookies_page = cookies.get(cookie_dash_id_module); 
                        SetListPag('pageDash_id_module', cookies_page); 
                    }
                    
                    if(cookies.get(cookie_dash_id_classe)){
                        let cookies_page = cookies.get(cookie_dash_id_classe); 
                        SetListPag('pageDash_id_classe', cookies_page); 
                    }

                    SetUserData('user', response.data.user);    
                    SetUserData('access_type', response.data.access_type);

                break;

            default:
                    CallbackError();
                    cookies.remove(cookie_passw, "", '/', cookiesRegister);
                    cookies.remove(cookie_email, "", '/', cookiesRegister);
                break;
        }
    }).catch((error)=>{
        CallbackError();        
        cookies.remove(cookie_passw, "", '/', cookiesRegister);
        cookies.remove(cookie_email, "", '/', cookiesRegister);
    })
}

export function Register(regName, regEmail, regCpf, regBirthDate, regPhone, regCountry, regLogin, regAddress, regCep, regCity, regUf, regPass_1, CallbackSuccess, CallbackError, CallbackEmail){ 
    const cookies = new Cookies(); 

    const data    = new FormData();
    data.append('type', 'register');

    data.append('regName', regName);
    data.append('regEmail', regEmail);
    data.append('regCpf', regCpf);
    data.append('regBirthDate', regBirthDate);
    data.append('regPhone', regPhone);
    data.append('regCountry', regCountry);
    data.append('regLogin', regLogin);
    data.append('regAddress', regAddress);
    data.append('regCep', regCep);
    data.append('regCity', regCity);
    data.append('regUf', regUf);
    data.append('regPass_1', regPass_1);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.type) {
            case "certo":
                    CallbackSuccess();

                    // Obtém a data atual
                    const day = new Date();

                    // Adiciona 3 dias
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);

                    cookies.set(cookie_passw, response.data.user.pass, { path: '/', expires: add3Days }, cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, { path: '/', expires: add3Days }, cookiesRegister);
                    
                    SetUserData('user', response.data.user);    
                    SetUserData('access_type', response.data.access_type);
                break;

            case "erro":
                    CallbackError();
                break;
            case "e-mail existente":
                    CallbackEmail();
                break;
        }
    }).catch((error)=>{
        CallbackError();        
        cookies.remove(cookie_passw, "", '/', cookiesRegister);
        cookies.remove(cookie_email, "", '/', cookiesRegister);
    })
}

export function RecoverEmail(email, CallbackSuccess, CallbackError_Send, CallbackError_NotEmail, CallbackError){
    const data = new FormData();    
    data.append('type', 'recover_email');
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_AltPhoto(file, CallbackSuccess, CallbackError){ 
    const cookies    = new Cookies();    
    let return_pass  = cookies.get(cookie_passw);
    let return_email = cookies.get(cookie_email);

    const data = new FormData();
    data.append('type', 'alt_photo');

    data.append('file', file);
    data.append('email', return_email);
    data.append('password', return_pass);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {

        switch (response.data.type) {
            case "certo":
                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
            
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_AltData(name, login, email, birthDate, phone, file, country, address, cep, city, uf, cpf, pass, CallbackSuccess, CallbackError){ 

    const cookies = new Cookies();    
    const data    = new FormData();
    data.append('type', 'alt_data');

    data.append('name', name);
    data.append('login', login);
    data.append('email', email);
    data.append('birth_date', birthDate);
    data.append('phone', phone);
    data.append('file', file);
    data.append('country', country);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);
    data.append('cpf', cpf);
    data.append('pass', pass);

    data.append('ckecked_email', cookies.get(cookie_email));
    data.append('ckecked_password', cookies.get(cookie_passw));

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {  
           
        switch (response.data.type) {
            case "certo":
                    cookies.set(cookie_passw, response.data.user.pass, '/', cookiesRegister);
                    cookies.set(cookie_email, response.data.user.email, '/', cookiesRegister);

                    SetUserData('user', response.data.user);     
                    SetUserData('access_type', response.data.access_type); 
                    CallbackSuccess(); 
                break;
            
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_AttendedClasses(idUser, idCourse, idModule, idClasse, status, UpdateClasse){ 
    const data = new FormData();

    data.append('type', 'register_attended_classes');

    data.append('idUser', idUser);
    data.append('id_course', idCourse);
    data.append('id_module', idModule);
    data.append('id_classe', idClasse);
    data.append('status', status);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
        UpdateClasse();
    }).catch((error)=>{
    })
}

export function Reg_ClassesFinishedPorcentagem(idUser, idCourse, idModule, idClasses, UpdateClasse){ 
    const data = new FormData();

    data.append('type', 'classe_finished_porcent');

    data.append('idUser', idUser);
    data.append('id_course', idCourse);
    data.append('id_module', idModule);
    data.append('id_classe', idClasses);
    data.append('status', true);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
        UpdateClasse();
    }).catch((error)=>{
    })
}

export function Reg_Conclusion(idUser, idCourse, textConcl, listConcl, CallbackSuccess, CallbackError){ 
    const data = new FormData();

    data.append('type', 'register_conclusion');

    data.append('idUser', idUser);
    data.append('id_course', idCourse);
    data.append('text', textConcl);

    listConcl.forEach((elem, index)=> {
        data.append('conclusion_id[]', elem.id);
        data.append('conclusion_title[]', elem.title);
        data.append('conclusion_text[]', elem.text);
        data.append('conclusion_opt[]', elem.opt);

        elem.list.forEach((elem_1, index_1) => {
            data.append('list_id_given[]', elem_1.id_given);
            data.append('list_opt[]', elem_1.opt);
            data.append('list_title[]', elem_1.title);
            data.append('list_subtitle[]', elem_1.subtitle);
        })
    });  
    
    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {

        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_ClassesUploadFile(idUser, idCourse, idModule, idClasse, file, CallbackSuccess, CallbackError){ 
 
    const data = new FormData();
    data.append('type', 'classe_upload_file');

    data.append('idUser', idUser);
    data.append('id_course', idCourse);
    data.append('id_module', idModule);
    data.append('id_classe', idClasse);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        SetUserData('user', response.data.user);     
        SetUserData('access_type', response.data.access_type); 
        CallbackSuccess(); 
        
    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_ResetPassw(password, code, CallbackSuccess, CallbackError){ 
    const data = new FormData();
    data.append('type', 'reset_passw');

    data.append('password', password);
    data.append('number', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "ok!":
                    CallbackSuccess(); 
                break;
            default:
                    CallbackError();
                break;
        }       

    }).catch((error)=>{
        CallbackError();
    })
}

export function Reg_PaymentVoucher(date, file, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies();   
    const data    = new FormData();

    data.append('type', 'add_payment_voucher');

    data.append('date', date);
    data.append('file', file);

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));

    Axios({
        url : process.env.REACT_APP_API_URL + 'php/add_payment_voucher.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })   
    .then(response => {
        if(response.data == "certo"){
            CallbackSuccess(); 
        }else {
            CallbackError();
        }
        // switch (response.data.type) {
        //     case "certo":
        //             SetUserData('user', response.data.user);     
        //             SetUserData('access_type', response.data.access_type); 
        //             CallbackSuccess(); 
        //         break;
            
        //     default:
        //             CallbackError();
        //         break;
        // }

    }).catch((error)=>{
        CallbackError();
    })
}