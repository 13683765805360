import { useState, useEffect } from "react";

import './CourseDashStarted.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";

export default function Model_CourseDashStarted(props){
    
    const [ courseStated, setCourseStated ] = useState(CheckedCourse(0));
    const [ courseFinished, setCourseFinished ] = useState(CheckedCourse(1));
    
    function CheckedCourse(type){
        const newData = [];        
        GetUserData('user').courses_started.map((elem, index)=>{
            if(elem.status == type){
                if(GetDataPage('course').find(item => item.id == elem.id_course)){
                    if(newData.find(item => item.id == elem.id_course)){}else {
                        newData.push(GetDataPage('course').find(item => item.id == elem.id_course));
                    }
                }
            }
        });
        if(newData.length > 0){
            return newData;
        }else {
            return [];
        }     
    }

    function StatusCourse(data, idCourse){
        const qtdClasse = [];
        data.list_module.map((elem, index)=>{
            if(GetUserData('user').nivel == 3){
                qtdClasse.push(elem.list_classe.length);
            }else {
                let showQtdClasse = elem.list_classe.filter(item => item.type == 'classe');
                qtdClasse.push(showQtdClasse.length);
            }
        });

        let total         = 0;
        let courseStarted = GetUserData('user').courses_started.filter(item => item.id_course == idCourse).length;
        let conclusion    = GetUserData('user').continue.filter(item => item.id_course == idCourse).length;

        qtdClasse.map((elem, index)=>{
            total = elem + total;
        });   

        return(
            courseStarted == 0 ? null :
            <div className="div_marking">
                <div className="div_triangle" />
                <div className="text_status">
                    {
                        total == conclusion ? "Finalizado" :                         
                        conclusion == 0 ? "Curso iniciado" : "Concluído " + conclusion + " de " + total
                    }
                </div>                
            </div>
        )
    }

    return(
        <div className="Model_CourseDashStarted">
            <div className="container">
                <div className="title">Cursos iniciado</div>
                <div className="list_data_course">
                    {
                        courseStated.length > 0 ?
                        courseStated.map((elem, index)=>{
                            let statusCourse = true;
                            if(GetUserData('user').nivel == 3){
                                if(GetUserData('user').courses_status.find(item => item.id_course == elem.id)){
                                    let checkedCourse = GetUserData('user').courses_status.find(item => item.id_course == elem.id);
                                    statusCourse = checkedCourse.status;
                                    
                                }else {
                                    statusCourse = false;
                                }
                            } 
                            
                            return(
                                statusCourse ? 
                                <div className="div_data_course" key={ index } onClick={ ()=>{ SetListPag('pageDash_id_course', elem.id); SetListPag('pageDash', 'dash_classe_detalis') } }>
                                    <div className="courses_img">
                                        <img alt="img" src={ elem.file } className="img_" loading="lazy"/>
                                        {
                                            StatusCourse(elem, elem.id)
                                        }
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                                :
                                <div className="div_data_course" key={ index }>
                                    <div className="close_course">Aguardando liberação do professor!</div>
                                    <div className="courses_img">
                                        <img alt="img" src={ elem.file } className="img_" loading="lazy"/>
                                        {
                                            StatusCourse(elem, elem.id)
                                        }
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="">Nenhum curso iniciado até o momento...</div>
                    }
                </div>
                
                <div className="title">Cursos finalizados</div>
                <div className="list_data_course">
                    {
                        courseFinished.length > 0 ?
                        courseFinished.map((elem, index)=>{
                            let statusCourse = true;
                            if(GetUserData('user').nivel == 3){
                                if(GetUserData('user').courses_status.find(item => item.id_course == elem.id)){
                                    let checkedCourse = GetUserData('user').courses_status.find(item => item.id_course == elem.id);
                                    statusCourse = checkedCourse.status;
                                    
                                }else {
                                    statusCourse = false;
                                }
                            } 

                            return(
                                statusCourse ? 
                                <div className="div_data_course" key={ index } onClick={ ()=>{ SetListPag('pageDash_id_course', elem.id); SetListPag('pageDash', 'dash_classe_detalis') } }>
                                    <div className="courses_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                        <img alt="img" src={ elem.file } className="img_" loading="lazy"/>
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                                :
                                <div className="div_data_course" key={ index }>
                                    <div className="close_course">Aguardando liberação do professor!</div>
                                    <div className="courses_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                        <img alt="img" src={ elem.file } className="img_" loading="lazy"/>
                                        {
                                            StatusCourse(elem, elem.id)
                                        }
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="">Nenhum curso finalizado até o momento...</div>
                    }
                </div>
            </div>
        </div>
    )
}