import { useState, useEffect } from "react";

import './CourseDash.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SvgArrowLeft, SvgListline, SvgListSquare, SvgSearch } from "components/SvgFile";

export default function Model_CourseDash(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('course'));
    const [ listCategory, setListCategory ] = useState(GetDataPage('list_category'));
    
    const [ search, setSearch ] = useState('');
    const [ status, setStatus ]     = useState(false);
    const [ typeList, setTypeList ] = useState(false);
    const [ category, setCategory ] = useState('Mostrar todas as categorias');

    function OpenCourse(id){
    }

    function StatusCourse(data, idCourse){
        const qtdClasse = [];
        data.list_module.map((elem, index)=>{
            if(GetUserData('user').nivel == 3){
                qtdClasse.push(elem.list_classe.length);
            }else {
                let showQtdClasse = elem.list_classe.filter(item => item.type == 'classe');
                qtdClasse.push(showQtdClasse.length);
            }
        });

        let total         = 0;
        let courseStarted = GetUserData('user').courses_started.filter(item => item.id_course == idCourse).length;
        let conclusion = GetUserData('user').continue.filter(item => item.id_course == idCourse);
        let conclusionStatus = conclusion.filter(item => item.status == 1).length;

        qtdClasse.map((elem, index)=>{
            total = elem + total;
        });   

        return(
            courseStarted == 0 ? null :
            <div className="div_marking">
                <div className="div_triangle" />
                <div className="text_status">
                    {
                        total == conclusionStatus ? "Finalizado" : 
                        conclusionStatus == 0 ? "Curso iniciado" : "Concluído " + conclusionStatus + " de " + total
                    }
                </div>                
            </div>
        )
    }
    
    /* search */
    function SearchInput(type, value){
        if(type == "input"){
            if(value){
                const newList = [];
                GetDataPage('course').forEach(item =>{
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setDataPage(duplicate);
                setSearch(value);
            }else {
                setDataPage(GetDataPage('course'));
                setSearch('');
            }
        }else if(type == "select"){
            if(value == "all"){
                setDataPage(GetDataPage('course'));
                setCategory('Mostrar todas as categorias');

            }else {                
                const newList = [];
                GetDataPage('course').forEach(item =>{
                    item.category.forEach(item_1 =>{                    
                        if(item_1.name == value){
                            newList.push(item);
                        } 
                    }); 
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setDataPage(duplicate);
                setCategory(value);
            }
        }
    }
    function OpenCategory(value){
        let accordion = document.getElementById('list_category');
        if(value == true){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
            
        }else {
            accordion.style.maxHeight = null; 
        }
        setStatus(value);
    }
    /* end */

    return(
        <div className="Model_CourseDash">
            <div className="container">
                <div className="div_search">
                    {/* 
                    <div className="type_list" onClick={ ()=>{ setTypeList(!typeList) } }>
                        {
                            typeList ?
                            <SvgListline color="#193C4E" className="show_type_icon" /> :
                            <SvgListSquare color="#193C4E" className="show_type_icon" />
                        }
                    </div> 
                    */}
                    <div className="search">
                        <SvgSearch color="#ffffff" className="icons_search" />
                        <input type="text" className="input_search" onChange={ (e)=>{ SearchInput('input', e.target.value) } } value={ search } placeholder="pesquisar..." />
                    </div>
                    <div className="div_select">                        
                        <div className="select_category" onClick={ ()=>{ OpenCategory(!status) } }>
                            <div className="name_category">{ category }</div>
                            <div className="show_icon">
                                {
                                    status ?
                                    <SvgArrowLeft className="icons_arrow open_icon" color="#ffffff" /> : 
                                    <SvgArrowLeft className="icons_arrow" color="#ffffff" />
                                }
                            </div>
                        </div>
                        <div className="list_category" id="list_category">
                            {
                                category == "Mostrar todas as categorias" ? null : 
                                <div className="show_name_category" onClick={ ()=>{ OpenCategory(!status); SearchInput('select', "all") } }>
                                    Mostrar todas as categorias
                                </div>
                            }

                            {
                                listCategory.map((elem, index)=>{
                                    return(
                                        <div className="show_name_category" key={ index } onClick={ ()=>{ OpenCategory(!status); SearchInput('select', elem) } }>
                                            { elem }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="list_data_course">
                    {
                        dataPage.map((elem, index)=>{                            
                            let statusCourse = true;
                            if(GetUserData('user').nivel == 3){
                                if(GetUserData('user').courses_status.find(item => item.id_course == elem.id)){
                                    let checkedCourse = GetUserData('user').courses_status.find(item => item.id_course == elem.id);
                                    statusCourse = checkedCourse.status;
                                    
                                }else {
                                    statusCourse = false;
                                }
                            } 

                            return(
                                statusCourse ? 
                                <div className="div_data_course" key={ index } onClick={ ()=>{ SetListPag('pageDash_id_course', elem.id); SetListPag('pageDash', 'dash_classe_detalis') } }>
                                    <div className="courses_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                        {/* <img alt="img" src={ elem.file } className="img_" loading="lazy"/> */}
                                        {
                                            StatusCourse(elem, elem.id)
                                        }
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                                :
                                <div className="div_data_course" key={ index }>
                                    <div className="courses_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                        {/* <img alt="img" src={ elem.file } className="img_" loading="lazy"/> */}
                                        {
                                            StatusCourse(elem, elem.id)
                                        }
                                        <div className="close_course">
                                            {
                                                GetUserData('user').nivel == 3 ? "Aguardando liberação do professor!" : "Atualize a assinatura para liberar"
                                            }
                                        </div>
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}